
// Clickable links with dropdown in the header navigation

$(document).ready(function () {

    $('.navbar a.nav-link').click(function () {
        location.href = this.href;
    });

    $(".mobile-search-trigger").click(function () {
        if( !$("nav.header-navigation button.navbar-toggler").hasClass("collapsed") )
            $("nav.header-navigation button.navbar-toggler").click();

        $("header.header").toggleClass("active-ms");
    });

    $("nav.header-navigation button.navbar-toggler").click(function () {
        $("header.header").removeClass("active-ms");
    });

    $(".mm-trig").click(function () {
        // $(this).closest(".nav-item.dropdown").find(".dropdown-menu").css("display", "block");
        // console.log($(this).closest(".nav-link"));
        $(this).closest(".nav-item.dropdown").toggleClass("active-dropdown");
    });

});

// ------------------------------------------------------------------------------------------- //

var topSliderSpeed = homeTopSliderSettings.speed;
var topSliderEfect = homeTopSliderSettings.effect;
var topSliderDelay = homeTopSliderSettings.delay * 1000;
var topSliderAutoPlay = {
    delay: topSliderDelay,
    disableOnInteraction: true,
};
if( homeTopSliderSettings.autoplay == 0 )
    topSliderAutoPlay = false

// Hero Slideshow

var swiper = new Swiper('.hero-slider', {
  slidesPerView: 1,
  loop: true,
  speed:topSliderSpeed,
  autoplay: topSliderAutoPlay,
  effect:topSliderEfect,
  // loop: true,
  // speed:700,
  // effect : "flip",
  // 'slide', 'fade', 'cube', 'coverflow', 'flip'
  // autoplay: {
  //   delay: 5000,
  //   disableOnInteraction: true,
  // },
  spaceBetween: 15,
  keyboard: {
    enabled: true,
  },
  autoHeight: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

});


// ------------------------------------------------------------------------------------------- //

var textSliderSpeed = homeTextSliderSettings.speed;
var textSliderEfect = homeTextSliderSettings.effect;
var textSliderDelay = homeTextSliderSettings.delay * 1000;
var textSliderAutoPlay = {
    delay: textSliderDelay,
    disableOnInteraction: true,
};
if( homeTextSliderSettings.autoplay == 0 )
    textSliderAutoPlay = false

// OPDU Slideshow

var swiper = new Swiper('.opdu-slider', {
    slidesPerView: 1,
    loop: true,
    speed:textSliderSpeed,
    autoplay: textSliderAutoPlay,
    effect:textSliderEfect,
    spaceBetween: 20,
    autoHeight: true,
    keyboard: {
      enabled: true,
    },
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },

  // slidesPerView: 1,
  // autoplay: {
  //   delay: 5000,
  //   disableOnInteraction: true,
  // },
  // loop: true,
  // spaceBetween: 20,
  // autoHeight: true,
  // keyboard: {
  //   enabled: true,
  // },
  // pagination: {
  //   el: ".swiper-pagination",
  //   dynamicBullets: true,
  //   clickable: true,
  // },

});

$(".opdu-slider").hover(function () {
  (this).swiper.autoplay.stop();
}, function () {
  (this).swiper.autoplay.start();
});


// ------------------------------------------------------------------------------------------- //
